import { Component, computed, inject, WritableSignal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgOptimizedImage } from '@angular/common';
import { LanguagePickerComponent } from './shared/components/language-picker/language-picker.component';
import { travelerAppConfigToken, travelerOperatorConfigToken, travelerTranslationToken } from './shared/config/tokens';
import { TravelerOperatorConfig } from './shared/config/traveler-operator-config.model';
import { TravelerTranslationModel } from './shared/models/translation.model';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './map/info-dialog/info-dialog.component';
import { ApplicationInsightsService } from '@tbums.mono.web/tbums-lib';
import { TravelerAppConfig } from './shared/config/traveler-app-config.model';
import { TagService } from './shared/services/tag.service';

@Component({
  standalone: true,
  imports: [RouterModule, MatToolbarModule, NgOptimizedImage, LanguagePickerComponent, MatButtonModule],
  selector: 'traveler-root',
  template: ` <mat-toolbar
      id="toolbar"
      [style.background-color]="operatorConfig.primaryColor"
      [style.padding]="'32px 16px'"
      class="relative z-10 drop-shadow-2xl"
    >
      <img
        alt="traveler log"
        [width]="operatorConfig.logoWidth"
        height="10"
        [ngSrc]="operatorConfig.logo"
        class="mr-4"
        (click)="goHome()"
      />

      <span class="text-lg" [style.color]="operatorConfig.headerTextColor">{{ titleSignal() }} </span>
      <div class="w-full"></div>
      <button mat-icon-button (click)="openInfoDialog()">
        <span class="material-symbols-outlined text-white mr-1">info</span>
      </button>
      <traveler-language-picker></traveler-language-picker>
    </mat-toolbar>
    <router-outlet></router-outlet>`,
  styles: ``,
})
export class AppComponent {
  translation = inject<WritableSignal<TravelerTranslationModel>>(travelerTranslationToken);
  operatorConfig = inject<TravelerOperatorConfig>(travelerOperatorConfigToken);
  appConfig = inject<TravelerAppConfig>(travelerAppConfigToken);
  tagService = inject(TagService);
  router = inject(Router);
  dialog = inject(MatDialog);
  applicationInsightsService = inject(ApplicationInsightsService);
  titleSignal = computed(() => {
    if (this._translation()) {
      return this.tagService.metaDataLanguagePicker();
    } else {
      return '';
    }
  });
  private _translation = inject<WritableSignal<TravelerTranslationModel>>(travelerTranslationToken);

  constructor() {
    this.tagService.metaDataLanguagePicker();
    this.applicationInsightsService.initialization(this.appConfig.appInsightsInstrumentationKey);
  }

  goHome() {
    if (this.operatorConfig.domain != 'default') {
      window.location.href = this.operatorConfig.domain;
    } else {
      window.location.href = '/';
    }
  }

  openInfoDialog() {
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: this.titleSignal(),
      },
      maxWidth: '600px',
      maxHeight: '100vh',
    });
  }
}
