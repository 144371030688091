import { TravelerOperatorConfig } from '../../app/shared/config/traveler-operator-config.model';
import { Language } from '../../app/shared/models/translation.model';

export const operatorConfigurations: TravelerOperatorConfig[] = [
  {
    domain: 'default',
    // All operators except Stegra
    operators: [
      '1124',
      '1156',
      '1285',
      '1120',
      '1283',
      '1284',
      '1126',
      '1288',
      '1317',
      '1394',
      '1450',
      '1477',
      '1531',
      '1652',
      '1684',
      '1726',
      '1756',
      '1791',
      '1806',
      '1813',
      '1814',
      '1848',
    ],
    primaryColor: '#125428',
    secondaryColor: '#006F32',
    headerTextColor: '#ffffff',
    logo: 'assets/logos/default.png',
    logoWidth: 34,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Ersättningstrafik',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'sl',
    operators: ['1124', '1126', '1806'],
    primaryColor: '#2870F0',
    secondaryColor: '#12151A',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/sl.png',
    logoWidth: 50,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Ersättningstrafik',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'oresundstag',
    operators: ['1477', '1450', '1726'],
    primaryColor: '#D72B14',
    secondaryColor: '#000000',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/oresundstag.png',
    logoWidth: 120,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Ersättningstrafik',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'varmlandstrafik',
    operators: ['1284'],
    primaryColor: '#F9B001',
    secondaryColor: '#5A5757',
    headerTextColor: '#000000',
    logo: 'assets/logos/varmlandstrafik.png',
    logoWidth: 44,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Ersättningstrafik',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'sjnord',
    operators: ['1394'],
    primaryColor: '#2E364A',
    secondaryColor: '#D42239',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/sjnord.png',
    logoWidth: 120,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Buss for tog',
    defaultLanguage: Language.NB,
  },
  {
    domain: 'ga',
    operators: ['1317'],
    primaryColor: '#353649',
    secondaryColor: '#46485A',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/ga.png',
    logoWidth: 86,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Buss for tog',
    defaultLanguage: Language.NB,
  },
  {
    domain: 'vy',
    operators: ['1156', '1285', '1120', '1283', '1284', '1531'],
    primaryColor: '#019577',
    secondaryColor: '#00453E',
    headerTextColor: '#ffffff',
    logo: 'assets/logos/vy2.png',
    logoWidth: 70,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Ersättningstrafik',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'norrtag',
    operators: ['1120'],
    primaryColor: '#011C48',
    secondaryColor: '#004D9C',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/norrtag.png',
    logoWidth: 120,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Ersättningstrafik',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'vr',
    operators: ['1288, 1813'],
    primaryColor: '#333333',
    secondaryColor: '#14B250',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/vr.png',
    logoWidth: 50,
    swedishTitle: 'Ersättningstrafik',
    norwegianTitle: 'Buss for tog',
    dannishTitle: 'Erstatningstrafik',
    finnishTitle: 'Korvaava liikenne',
    englishTitle: 'Replacement traffic',
    fallbackTitle: 'Ersättningstrafik',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'event',
    operators: ['1756'],
    primaryColor: '#125428',
    secondaryColor: '#006F32',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/nobina.png',
    logoWidth: 86,
    swedishTitle: 'Event-bussar',
    norwegianTitle: 'Event-busser',
    dannishTitle: 'Event-busser',
    finnishTitle: 'Tapahtumabussit',
    englishTitle: 'Event buses',
    fallbackTitle: 'Event-bussar',
    defaultLanguage: Language.SV,
  },
  {
    domain: 'metro',
    operators: ['1814'],
    primaryColor: '#14143C',
    secondaryColor: '#8B0A03',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/metro.png',
    logoWidth: 50,
    swedishTitle: '',
    norwegianTitle: '',
    dannishTitle: '',
    finnishTitle: '',
    englishTitle: '',
    fallbackTitle: 'Metrobus',
    defaultLanguage: Language.DK,
  },
  {
    domain: 'stegra',
    operators: ['1834'],
    primaryColor: '#151515',
    secondaryColor: '#D278FF',
    headerTextColor: '#FFFFFF',
    logo: 'assets/logos/stegra.png',
    logoWidth: 120,
    swedishTitle: '',
    norwegianTitle: '',
    dannishTitle: '',
    finnishTitle: '',
    englishTitle: '',
    fallbackTitle: 'Transportation',
    defaultLanguage: Language.SV,
  },
];
