import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () => import('./map/map.component').then((m) => m.MapComponent),
  },
  {
    path: ':operator',
    loadComponent: () => import('./map/map.component').then((m) => m.MapComponent),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
