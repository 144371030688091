import { ApplicationInsightsService } from '@tbums.mono.web/tbums-lib';
import { ErrorHandler, inject, Injectable, isDevMode } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TravelerErrorHandler implements ErrorHandler {
  private _applicationInsightsService: ApplicationInsightsService = inject(ApplicationInsightsService);

  handleError(error: unknown) {
    //Application Insights
    if (error instanceof Error) {
      this._applicationInsightsService.logException(error);

      if (isDevMode()) {
        console.error(error);
      }
    }
  }
}
