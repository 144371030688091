import { inject, Injectable, WritableSignal } from '@angular/core';
import { Language, TravelerTranslationModel } from '../models/translation.model';
import { TravelerOperatorConfig } from '../config/traveler-operator-config.model';
import { Meta, Title } from '@angular/platform-browser';
import { travelerOperatorConfigToken, travelerTranslationToken } from '../config/tokens';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  operatorConfig = inject<TravelerOperatorConfig>(travelerOperatorConfigToken);
  private _translation = inject<WritableSignal<TravelerTranslationModel>>(travelerTranslationToken);
  private _titleService = inject(Title);
  private _metaService = inject(Meta);
  metaDataLanguagePicker() {
    let title: string;

    switch (this._translation().language) {
      case Language.SV: {
        title = this.operatorConfig.swedishTitle;
        break;
      }
      case Language.NB: {
        title = this.operatorConfig.norwegianTitle;
        break;
      }
      case Language.DK: {
        title = this.operatorConfig.dannishTitle;
        break;
      }
      case Language.FI: {
        title = this.operatorConfig.finnishTitle;
        break;
      }
      case Language.EN: {
        title = this.operatorConfig.englishTitle;
        break;
      }
    }

    this._titleService.setTitle(
      title ? `${title} - ${this._translation().seo.tagline}` : this.operatorConfig.fallbackTitle,
    );
    this._metaService.updateTag({ name: 'description', content: this._translation().seo.description });
    return title || this.operatorConfig.fallbackTitle;
  }
}
