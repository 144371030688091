import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app/app.routes';
import { provideHttpClient } from '@angular/common/http';
import { TravelerOperatorConfig } from './app/shared/config/traveler-operator-config.model';
import { operatorConfigurations } from './assets/config/operatorConfigurations';
import { ErrorHandler, importProvidersFrom, signal } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  travelerAppConfigToken,
  travelerOperatorConfigToken,
  travelerTranslationToken,
} from './app/shared/config/tokens';
import { translationEN } from './app/shared/i18n/en';
import { Language } from './app/shared/models/translation.model';
import { translationSV } from './app/shared/i18n/sv';
import { translationDK } from './app/shared/i18n/dk';
import { translationFI } from './app/shared/i18n/fi';
import { translationNB } from './app/shared/i18n/nb';
import { ApplicationInsightsService } from '@tbums.mono.web/tbums-lib';
import { TravelerErrorHandler } from './app/shared/services/traveler-error-handler.service';
import { TravelerAppConfig } from './app/shared/config/traveler-app-config.model';

const translationFactory = (travelerConfig: TravelerOperatorConfig) => getTranslation(travelerConfig);

const operatorConfigFactory = () => getTravelerOperatorConfig();

const fetchAppConfig = async () => {
  const url = './assets/generated-config/app-config.json';
  await fetch(url, { cache: 'no-store' })
    .then((response) => response.json())
    .then((config: TravelerAppConfig) => bootstrapApp(config));
};

const bootstrapApp = (config: TravelerAppConfig) => {
  bootstrapApplication(AppComponent, {
    providers: [
      provideRouter(appRoutes),
      provideHttpClient(),
      importProvidersFrom(BrowserAnimationsModule),
      {
        provide: travelerAppConfigToken,
        useValue: config,
      },
      {
        provide: travelerOperatorConfigToken,
        useFactory: operatorConfigFactory,
      },
      {
        provide: travelerTranslationToken,
        useFactory: translationFactory,
        deps: [travelerOperatorConfigToken],
      },
      ApplicationInsightsService,
      {
        provide: ErrorHandler,
        useClass: TravelerErrorHandler,
      },
    ],
  }).catch((err) => console.error(err));
};

function getTravelerOperatorConfig(): TravelerOperatorConfig {
  const path = window.location.pathname.split('/')[1].replace(/\//g, '').toLowerCase();
  let selectedConfig;
  if (path === '') {
    selectedConfig = operatorConfigurations[0];
  } else {
    operatorConfigurations.forEach((config) => {
      if (config.domain.toLowerCase() === path) {
        selectedConfig = config;
      }
    });

    if (!selectedConfig) {
      window.location.href = '/';
      return operatorConfigurations[0];
    }
  }

  setFavIcon(selectedConfig);
  return selectedConfig;
}

function setFavIcon(config: TravelerOperatorConfig) {
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = config.logo;
}

function getTranslation(travelerConfig: TravelerOperatorConfig) {
  const nLanguage = navigator.language;
  const lsLanguage = localStorage.getItem('language');

  let selectedLanguage;
  if (lsLanguage) {
    selectedLanguage = lsLanguage;
  } else if (nLanguage.split('-')[0] in Language) {
    selectedLanguage = nLanguage.split('-')[0];
  } else {
    selectedLanguage = travelerConfig.defaultLanguage;
  }

  switch (selectedLanguage) {
    case Language.SV:
      return signal(translationSV);
    case Language.DK:
      return signal(translationDK);
    case Language.FI:
      return signal(translationFI);
    case Language.NB:
      return signal(translationNB);
    default:
      return signal(translationEN);
  }
}

fetchAppConfig()
  .then()
  .catch((err) => console.error(err));
